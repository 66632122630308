import React from 'react';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import BannerWrapper from './banner.style';

const Banner = ({ title, description }) => {
  return (
    <BannerWrapper>
      <h1>OUR HAPPY CUSTOMERS</h1>
    </BannerWrapper>
  );
};

export default Banner;
